@media (min-width: 951px) {

    .page-wrapper .nav-sections {
        &, & .navigation, & .navigation > .menu > ul, & .navigation > .menu > ul a.level-top {
            background: $white;
        }
    }

    .page-wrapper .nav-sections {

        .navigation {
            border-width: 1px 0 1px 0;
            border-style: solid;
            border-color: $light;

            > .menu > ul {

                > li {
                    height: 54px;
                }
                
                a.level-top {
                    color      : $dark;
                    font-weight: 700;
                    line-height: 54px;

                    span img {
                        display: none;
                    }
                    
                }

            }

        }

    }

}