
@media (min-width: 951px) {

    .page-header {
        position: relative;

        &::before {
            height: 36px;
            width: 100vw;
            left: 50%;
            position: absolute;
            display: block;
            background: $red;
            transform: translateX(-50%);
        }   

    }

    .page-header .header.content {
        height : auto;
        padding: 13px 0 0;

        .header-logo.row,
        .header-meta > .row {
            display        : flex;
            align-items    : center;
            justify-content: space-between;

            > div {
                flex     : 1 0 auto;
                max-width: 100%;
                position : relative;
            }

        }

        .header-logo {

            .logo {
                padding-top   : 25px;
                padding-bottom: 25px;              
            }

        }

    }

}

.page-header .header.content .header-logo {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .logo img {
        height    : auto !important;
        max-height: 90px !important;
        max-width : 100% !important;
    }

    #searchContainerHead {
        /* ALTE CSS Überschreiben */
        padding: 0;
        float: none;
        margin: 0 0 0 50px;
        top: 0;
        
        .searchBox {
            height: 46px;
            float : right;
            width: 400px;

            input {
                border: 1px solid $red;
                height: 46px;
                padding-right: 40px;
            }

            .icon-suche {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                font-size: 20px;
                z-index: 99;
                width: 46px;
                height: 46px;
                background: $red;
                color: white;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            
            /* ALTE CSS Überschreiben */
                background: transparent;
                visibility: visible;
                opacity: 1;
                position: relative;
                padding: 0;
                top: auto;
        }

    }

}



@media (max-width: 559px) {

    .page-header .header.content .header-logo {
        justify-content: center;
        flex-wrap: wrap;
        padding-top: 0 !important;
        width: 100vw;
        
        .logo {
            margin-left: 0;
            max-width  : 75%;
        }

        #searchContainerHead {
            margin-top: 15px;
            margin-left: 0;
            max-width  : 75%;
            width: 100%;

            .searchBox {
                background: white;
                width: 100%;
                height: 36px;

                .icon-suche {
                    height: 36px;
                    width: 36px;
                    font-size: 18px;
                }

                input {
                    padding-right: 36px;
                    height: 36px;
                }

            }

        }

    }

}

@media (max-width: 480px) {

    .page-header .header.content .header-logo {
        
        .logo,
        #searchContainerHead {
            max-width  : 100%;
        }

    }

}

.page-header .header.content .header-logo #searchContainerHead .searchBox {

    @media (min-width: 560px) {
        width: 200px;
    }

    @media (min-width: 768px) {
        width: 300px;
    }

    @media (min-width: 1024px) {
        width: 400px;
    }

}