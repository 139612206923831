@media (min-width: 951px) {

    .page-header .header.content .header-meta {
        background : $red;
        font-size  : 14px;
        font-weight: 600;
        position   : absolute;
        top        : 0;
        width      : 100%;

        .row {
            height: 36px;
            margin: 0;

            > div {

                i {
                    font-size  : 18px;
                    margin-left: 12px;
                }

                &.freecall i {
                    margin-left : 7px;
                    margin-right: 12px;
                }

            }

        }

        &, & a {
            color: white;
        }

        .actions {
            text-align: right;
            padding-right: 7px;

            > div {
                display: block;
                float  : right;
                width  : 100px;
            }

            #menuUser {

                > a {
                    height: 36px;
                    display: flex;
                    align-items: center;
                }

                .menuTooltip {
                    display   :none;
                    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23); 
                    background: white;
                    padding   : 10px;
                    position  : absolute;
                    width     : 200px;
                    height    : 160px;
                    right     : 0;
                    top       : 36px;
                    z-index   : 9999 !important;

                    input {
                        margin-bottom: 5px;
                    }

                    button {
                        font-size: 14px;
                        padding  : 8px;
                    }

                    a {
                        font-size: 10px;
                        color    : $dark;
                    }

                }

                &:hover .menuTooltip {
                    display: block;
                }

            }

            .minicart-wrapper #minicart-content-wrapper {
                color     : $dark;
                text-align: center;
            }

            ul {
                list-style: none;
                margin    : 0;
                padding   : 0;
                float: right;

                > li {
                    float: left;
                    margin-left  : 25px;
                    margin-bottom: 0;
                }

            }

        }

    }

    .page-header .header.content .quicklinks {
        height  : 36px;
        margin  : 0 !important;
        position: absolute;
        top     : 0;
        right   : 150px;
        width   : 120px !important; 
        z-index : 9;
        display : flex;
        align-items: center;

        > .header.links {
            list-style: none;
            margin    : 0;
            width     : 120px;

            > .minicart-wrapper {
                border-radius: 0;
                background: none !important;
                font-size : 14px;
                position  : relative !important;
                text-align: left;
                height    : auto !important;
                width     : auto !important;
                padding   : 0 !important;
                right     : auto !important;
                top       : auto !important;
                width     : 120px !important; 

                > .showcart {
                    position: relative;
                    display: flex;
                    align-items: center;
                    width: 100%;

                    &::before {
                        content    : 'Warenkorb';
                        display    : inline-block;
                        color      : white;
                        font-family: 'Open Sans','Helvetica Neue',Helvetica,Arial,sans-serif;
                        font-size  : 14px;
                        font-weight: 600;
                        text-transform: none;
                    }

                    .fa-shopping-cart {
                        margin-left: 12px;
                    }

                    .fa-shopping-cart,
                    .counter.qty > .counter-number:first-of-type {
                        font-size  : 18px !important;
                        width      : auto;
                    }

                    .counter.qty {
                        background: transparent !important;
                        left: auto !important;
                        position: relative !important;
                        border-radius: 0 !important;
                        padding: 0;
                        margin: 0;

                        > .counter-number .fa-shopping-cart {
                            margin: 0;
                        }

                        > .counter-number:last-of-type {
                            font-size: 10px !important;
                            margin: 0 !important;
                            color: white !important;
                            width: 16px !important;
                            height: 16px !important;
                            line-height: 16px !important;
                            background: #089C08 !important;
                            position: absolute;
                            top: 0;
                            right: 4px;
                        }

                    }

                }



            }

        }

    }

    .page-header .header.content .customer-welcome {
        width: 100vw;
        position: absolute;
        top: 38px;
        font-size: 12px;
        text-align: right;
    }

}

@media (max-width: 950px) {

    .page-header .header.content .header-meta {
        display: none;
    }
    
}