.cms-suche #maincontent {

    .blue-line {
        display: none;
    }

    #searchContainerContent {
        margin-top: -25px;

        @media (max-width: 950px) {
            margin-top: -35px;
        }

    }

}

